import img1 from "../image/1.png";
import img2 from "../image/2.png";
import img3 from "../image/3.png";
import img4 from "../image/4.png";
import Slider from "react-slick";

function HomeOurTeam() {
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 2,
    dots: true,
    //centerMode: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="home-our-team">
      <div className="container">
        <div className="row">
          <div className="our-team-slider" id="team">
            <h2>Our Team</h2>
            <div className="team-slider">
            <Slider {...settings}>
            <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img1} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Knuckles</h5>
                      <p>Society Founder</p>
                    </div>
                  </div>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img2} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Astergenboy</h5>
                      <p>Community And Marketing Manger</p>
                    </div>
                  </div>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img3} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Vanity</h5>
                      <p>Lead Developer</p>
                    </div>
                  </div>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img4} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Aegon</h5>
                      <p>Social Media Manager</p>
                    </div>
                  </div>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img2} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Vanity</h5>
                      <p>Lead Developer</p>
                    </div>
                  </div>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img4} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Aegon</h5>
                      <p>Social Media Manager</p>
                    </div>
                  </div>
                </Slider>
              {/* <ul className="slick-slider">
                <li>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img1} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Knuckles</h5>
                      <p>Society Founder</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img2} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Astergenboy</h5>
                      <p>Community And Marketing Manger</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img3} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Vanity</h5>
                      <p>Lead Developer</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img4} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Aegon</h5>
                      <p>Social Media Manager</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img2} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Vanity</h5>
                      <p>Lead Developer</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="team-box">
                    <div className="team-img">
                      <div className="team-user">
                        <img src={img4} />
                      </div>
                    </div>
                    <div className="team-box-con">
                      <h5>Aegon</h5>
                      <p>Social Media Manager</p>
                    </div>
                  </div>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeOurTeam;
