import Logo from "../image/logo.png";
import MenuIc from "../image/menu-ic.png";
import Hic1 from "../image/h-ic1.png"
import Hic2 from "../image/h-ic2.png";
import Hic3 from "../image/h-ic3.png";
import Hic4 from "../image/h-ic4.png";

function Header() {
  return (
    <header className="header-main">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <a className="navbar-brand" href="#">
            <img src={Logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src={MenuIc} />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#society">
                  Society
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#team">
                  Team
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#faq">
                  FAQ
                </a>
              </li>
            </ul>
            <form className="form-inline my-2 my-lg-0">
              <a
                className="h-ic"
                href="https://solanart.io/collections/degenapesociety"
              >
                <img src={Hic1} />
              </a>
              <a
                className="h-ic"
                href="https://magiceden.io/marketplace/degen_ape_society"
              >
                <img src={Hic2}  />
              </a>
              <a className="h-ic" href="https://twitter.com/DegenApeSociety">
                <img src={Hic3} />
              </a>
              <a
                href="https://discord.com/invite/DegenApeSociety"
                className="btn get-in-touch"
              >
                Join us on Discord <img src={Hic4} />
              </a>
            </form>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
