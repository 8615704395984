import clogo1 from "../image/c-logo1.png";
import clogo2 from "../image/c-logo2.png";
import clogo3 from "../image/c-logo3.png";
import clogo4 from "../image/c-logo4.png";

function HomeClient() {
  return (
    <section className="home-client">
      <div className="container">
        <div className="row">
          <div className="client-logos">
            <div className="logos">
              <a href="https://solana.com/">
                <img src={clogo1} />
              </a>
            </div>
            <div className="logos">
              <a href="https://www.civic.com/solutions/verified-by-civic-pass/">
                <img src={clogo2} />
              </a>
            </div>
            <div className="logos">
              <a href="https://magiceden.io/marketplace/degen_ape_society">
                <img src={clogo3} />
              </a>
            </div>
            <div className="logos">
              <a href="https://solanart.io/collections/degenapesociety">
                <img src={clogo4} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeClient;
