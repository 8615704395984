import Hic1 from "../image/h-ic1.png"
import Hic2 from "../image/h-ic2.png";
import Hic3 from "../image/h-ic3.png";
import Hic4 from "../image/h-ic4.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-left">
            <h2>
              For the Wilds, Misfits <br />
              and Degens
            </h2>
            <p>© 2022, Degen Ape Society</p>
          </div>
          <div className="footer-right">
            <h5>Follow us:</h5>
            <ul>
              <li>
                <a href="https://discord.com/invite/DegenApeSociety">
                  <img src={Hic4} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/DegenApeSociety">
                  <img src={Hic3} />
                </a>
              </li>
              <li>
                <a href="https://magiceden.io/marketplace/degen_ape_society">
                  <img src={Hic2} />
                </a>
              </li>
              <li>
                <a href="https://solanart.io/collections/degenapesociety">
                  <img src={Hic1} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
