import arts1 from "../image/arts1.png";
import arts2 from "../image/arts2.png";
import arts3 from "../image/arts3.png";
import arts4 from "../image/arts4.png";
import arts5 from "../image/arts5.png";

function HomeDegenApe() {
  return (
    <section className="home-degen-ape">
      <div className="container">
        <div className="row">
          <div className="degen-ape-con-top">
            <div className="degen-ape-left">
              <h2>Degen Ape</h2>
              <p>
                Degen Ape is a Collection of 1020 unique &amp; programmatically
                generated Apes that are ready to rule the Solana Forests. They
                are here to conquer &amp; stay.{" "}
              </p>
              <a className="button1" href="#">
                discover arts
              </a>
            </div>
            <div className="degen-ape-right">
              <div className="ape-imgleft">
                <img src={arts1} />
              </div>
              <div className="ape-imgright">
                <img src={arts2} />
                <img src={arts3} />
              </div>
            </div>
          </div>
          <div className="degen-ape-con-bot">
            <div className="degen-ape-left">
              <div className="ape-imgleft">
                <img src={arts4} />
              </div>
              <div className="ape-imgright">
                <img src={arts5} />
              </div>
            </div>
            <div className="degen-ape-right">
              <h2>Lady Ape</h2>
              <p>
                Degen Lady Ape is a collection of 3777 gorgeous, beautiful &amp;
                modern Lady Apes that will settle down with Degen Apes &amp;
                reproduce Degen Baby Apes. They are here to turn a House into a
                Home with their love &amp; joy.
              </p>
              <a className="button1" href="#">
                discover arts
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeDegenApe;
