import logo from './logo.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeTop from './components/HomeTop';
import HomeDegenApe from './components/HomeDegenApe';
import HomeRoadmap from './components/HomeRoadMap';
import HomeOurTeam from './components/HomeOurTeam';
import HomeFaq from './components/HomeFaq';
import HomeClient from './components/HomeClient';
import HomeAbout from './components/HomeAbout';


function App() {
  return (
    <div className="App">
      <Header/>
      <HomeTop/>
      <HomeAbout/>
      <HomeDegenApe/>
      <HomeRoadmap/>
      <HomeOurTeam/>
      <HomeFaq/>
      <HomeClient/>
      <Footer/>
    </div>
  );
}

export default App;
