import React, { useEffect } from "react";
import haboutic from "../image/h-about-ic.png";
import AOS from "aos";
import "aos/dist/aos.css";

function HomeAbout() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="home-about" id="society">
      <div className="container">
        <div className="row">
          <div className="about-con">
            <h2>Degen Ape Society</h2>
            <p>
              Degen Ape Society is a Family of Apes, Lady Apes, Baby Apes
              that'll live in the Ape Village located in the <br />
              Metaverse. Degen Ape Society is for all the misfits, degens &amp;
              rebels of the Solana World.
            </p>
            <div className="about-con-inner" data-aos="fade-up">
              <div className="about-con-box">
                <div className="about-con-ic">
                  <span>
                    <img src={haboutic} />
                  </span>
                </div>
                <div className="about-con-box-con">
                  <h3>Own</h3>
                  <p>
                    Own a Cool, Admirable &amp; Appealing Ape &amp; a Beautiful,
                    Loving &amp; Caring Lady Ape to reproduce Degen Baby Apes
                    &amp; continue the family.
                  </p>
                </div>
              </div>
              <div className="about-con-box">
                <div className="about-con-ic">
                  <span>
                    <img src={haboutic} />
                  </span>
                </div>
                <div className="about-con-box-con">
                  <h3>Play &amp; Earn</h3>
                  <p>
                    Receive $AP Tokens on a daily basis. Gamble your $AP Tokens
                    to Play our P2E game to Win guaranteed cool &amp; expensive
                    NFT's of the Solana Universe.
                  </p>
                </div>
              </div>
              <div className="about-con-box">
                <div className="about-con-ic">
                  <span>
                    <img src={haboutic} />
                  </span>
                </div>
                <div className="about-con-box-con">
                  <h3>Explore</h3>
                  <p>
                    Share the love, meet new apes, make friends &amp; build a
                    family of Apes through the amazing ecosystem we've created
                    on our Discord &amp; our upcoming Degen ApeVerse.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeAbout;
