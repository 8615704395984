import React, { useEffect, useState } from "react";
import cdott from "../image/c-dott.png";

const calculateTimeLeft = () => {
  let year = new Date().getFullYear();
  let difference = +new Date(`01/11/${year} 21:25:00`) - +new Date();

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  
  return timeLeft;
};

function HomeTop() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <section className="home-top">
      <div className="container">
        <div className="row">
          <div className="home-top-con">
            <h1>
              Owning a Degen Ape <br />
              gives you a ton of benefits
            </h1>
            <a className="button1" href="https://magiceden.io/launchpad">
              public mint
            </a>
            <div className="top-counter">
              <div className="counter-box">
                <h2>
                  {timeLeft.days} <br />
                  <small>Days</small>
                </h2>
              </div>
              <div className="c-dott">
                <img src={cdott} />
              </div>
              <div className="counter-box">
                <h2>
                {timeLeft.hours} <br />
                  <small>Hours</small>
                </h2>
              </div>
              <div className="c-dott">
                <img src={cdott} />
              </div>
              <div className="counter-box">
                <h2>
                {timeLeft.minutes} <br />
                  <small>Minutes</small>
                </h2>
              </div>
              <div className="c-dott">
                <img src={cdott} />
              </div>
              <div className="counter-box">
                <h2>
                {timeLeft.seconds}  <br />
                  <small>Seconds</small>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeTop;
