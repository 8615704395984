import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function HomeRoadmap() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="home-roadmap" id="roadmap">
      <div className="container">
        <div className="row">
          <div className="roadmap-con-top">
            <h2>Roadmap</h2>
            <p>
              Before starting, we wanted to emphasize the importance of our
              community! ALL updates &amp; subsequent development will be
              discussed &amp; voted upon by the community within, to offer the
              best solutions. Our Roadmap will constantly be updated with new
              ideas from our Community.
            </p>
            <div className="time-line-inner">
              {/* The Timeline */}
              <ul className="timeline">
                {/* Item 1 */}
                <li data-aos="fade-up">
                  <div className="direction-r">
                    <div className="flag-wrapper">
                      <h4>NFT Launch (Phase 1)</h4>
                    </div>
                    <div className="desc">
                      The creation of the Degen Ape Society art along with
                      effective marketing to spread awareness of our Elite Apes.
                      The Degen Ape's are scheduled to rule over the Solana
                      Forest's on the 19th of December (Completed &amp; Sold Out
                      in 2 Minutes).
                    </div>
                  </div>
                </li>
                {/* Item 2 */}
                <li data-aos="fade-up">
                  <div className="direction-l">
                    <div className="flag-wrapper">
                      <h4>Ready for War (Phase 2)</h4>
                    </div>
                    <div className="desc">
                      The Apes are ready for war. The creation of a Holder Dao
                      with Alpha's of the Space to get insights on NFT's &amp;
                      the Crypto World. Apes unite together &amp; flood the
                      world with Degen Apes to make more Living Beings aware
                      about our Apes. The development of our P2E game &amp; $AP
                      (Ape Power) Token begins.
                    </div>
                  </div>
                </li>
                {/* Item 3 */}
                <li data-aos="fade-up">
                  <div className="direction-r">
                    <div className="flag-wrapper">
                      <h4>Ruling Solana Forest (Phase 3)</h4>
                    </div>
                    <div className="desc">
                      The Apes have taken over the Solana Forest &amp; are ready
                      to settle down with Degen Lady Apes. The Launch of Lady
                      Apes will take place. All holder's of Degen Apes will be
                      given priority to Mint the Degen Lady Apes. We start
                      ramping up things &amp; use efficient marketing tools to
                      promote the Degen Ape Society as a whole.
                    </div>
                  </div>
                </li>
                {/* Item 2 */}
                <li data-aos="fade-up">
                  <div className="direction-l">
                    <div className="flag-wrapper">
                      <h4>Gen2 &amp; P2E Game (Phase 4)</h4>
                    </div>
                    <div className="desc">
                      Daily Airdrops of the $AP begin. Breeding will be
                      introduced &amp; Holder's of Degen Apes &amp; Degen Lady
                      Apes will be able to reproduce Degen Baby Apes by burning
                      their $AP tokens. Our play to earn NFT game will be
                      launched. Holder's can engage with our Play to Earn Game
                      to earn more $AP tokens &amp; Win expensive NFT's.{" "}
                    </div>
                  </div>
                </li>
                {/* Item 3 */}
                <li data-aos="fade-up">
                  <div className="direction-r">
                    <div className="flag-wrapper">
                      <h4>Building a Village for the Apes (Phase 5)</h4>
                    </div>
                    <div className="desc">
                      The creation of the art of Degen Ape Village begins.
                      Deploying Apes to the Metaverse to cause Chaos in the
                      Solana Forest &amp; build relationships with fellow apes.
                      The Creation of 1/1 Legendary Apes Begin in collaboration
                      with renowned artists of the NFT Space.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeRoadmap;
